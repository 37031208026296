<template>
    <b-container fluid>
      <b-row :style="{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', color:'#7f7f7f'}">
        
        <b-col md="12">
          <!-- <img alt="Bufu logo" src="../assets/notfound-code.png" width="340" > -->
          <v-icon :color="isSuccess ? 'green' : 'red'" x-large>{{isSuccess ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
          <h1 :class="`text-h4 font-weight-light mt-4`">
            {{isSuccess ? 'BERHASIL' : 'GAGAL'}}
          </h1>
          <h1 :class="`text-subtitle-1 font-weight-light mt-4`">{{message}}</h1>
          <v-btn v-if="isSuccess" class="submit" rounded color="primary" dark href="https://gmail.com/" width="150">BUKA EMAIL</v-btn>
          <br />
          <v-btn class="submit mt-4" rounded color="green" dark to="/login" @click="onClick" width="150">LOGIN</v-btn>
        </b-col>
        <b-col md="12" class="d-none d-sm-block">
          <img alt="Bufu logo" src="../assets/bufu-logo-load.png" width="200"><br/>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  props: ['status'],
  data(){
    return{
      isSuccess: this.status == 1,
      message: 'Tagihan anda sudah diproses, silahkan buka email untuk melihat rincian pembayaran lebih lanjut. Password untuk login akan dikirim bersama kwitansi pembayaran melalui email, bila pembayaran sudah dikonfirmasi'
    }
  },
  methods: {
    onClick() {
      this.$store.commit('setValidateRegister', null)
    }
  },
  created(){
    if(!this.isSuccess) this.message = 'Tagihan anda belum bisa diproses untuk saat ini, silahkan coba lagi nanti'
  }
}
</script>
<style scoped>
.submit{
  text-decoration: none;
}

h5{
  font-size: 15px;
}

</style>